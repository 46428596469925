<template>
  <div class="comment-box">
    <div class="comment-box-content">
      <div class="main"><CommentReply /></div>
      <form class="comment-box-main main" id="comment-data" enctype="multipart/form-data" @submit.prevent="">
        <div class="comment-box-options">
          <a class="comment-box-button __avatar" :style="`--picture:url(${$user.picture})`">
            <span v-if="!$user.picture">{{ $user.name[0] }}</span>
          </a>
        </div>
        <div class="comment-box-text" @input="onResize">
          <TextareaRich name="message" placeholder="Escribir..." @input="inputMessage" :encode="true" @paste.prevent="onPaste" />
        </div>
        <div class="comment-box-s end">
          <a class="comment-box-button __send" v-if="loading"><iconic name="gspinner" /></a>
          <a class="comment-box-button __send" v-else @click="sendComment"><iconic name="send" /></a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  components: {
    TextareaRich: () => import("../widgets/TextareaRich.vue"),
    CommentReply: () => import("./CommentReply.vue"),
  },
  data: function() {
    return {
      loading: false,
      message: "",
    };
  },
  methods: {
    inputMessage: function({ target: { innerHTML } }) {
      this.message = encodeURIComponent(innerHTML || "");
    },
    commentResponse: function({ data: { success, message, comment } }) {
      this.loading = false;
      if (!success) {
        return this.ialert.go({ type: "error", title: "Error", message });
      }
      const messagebox = document.querySelector("#comment-data [name=message]");
      messagebox.innerHTML = "";
      this.message = "";
      this.$store.commit("addNewComment", comment);
      this.$store.commit("setReplyComment", null);
    },
    sendComment: async function() {
      this.message = this.message.trim();
      if (!this.message) return;
      try {
        this.loading = true;
        const formData = new FormData(document.querySelector("#comment-data"));
        formData.append("post", this.post);
        formData.append("comment", this.message);
        if (this.replyComment) {
          formData.append("reply", this.replyComment?._id);
          formData.append("parent", this.replyComment?.parent ? this.replyComment?.parent : this.replyComment?._id);
          formData.append("deep", this.replyComment.deep + 1);
        }
        this.$store.dispatch("saveComment", { formData, callback: this.commentResponse });
      } catch (error) {
        this.loading = false;
        this.ialert.go({ type: "error", title: "Error", message: error.message });
      }
    },
    onResize: function({ target }) {
      const { height } = target.getBoundingClientRect();
      const list = document.querySelector(".view-comments-list");
      list.style.marginBottom = `${height}px`;
    },
  },
  computed: {
    replyComment: function() {
      return this.$store.state.comments.replyComment;
    },
  },
};
</script>

<style lang="scss">
.comment-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: $dshadow;
  background-color: #fff;
  &-content {
    padding: $mpadding/2 $mpadding;
  }
  &-main {
    @include Flex(inherit, center, center);
    gap: $mpadding;
  }
  &-text {
    flex: 1;
    [name="message"] {
      padding: $mpadding/1.2 $mpadding;
      border-radius: $mpadding * 2;
      background-color: #f4f4f4;
      border: none;
      width: 100%;
      min-height: $mpadding * 2.5;
      margin: 0;
    }
  }
  &-button {
    display: flex;
    padding: $mpadding;
    margin: 0 $mpadding/2;
    user-select: none;
    &.__avatar {
      @include Flex(inherit, center, center);
      width: $mpadding * 3;
      height: $mpadding * 3;
      @include Avatar($mpadding * 2);
      color: #fff;
      font-weight: bold;
      font-size: 120%;
    }
    &.__send {
      color: $primary_color;
    }
    &:not(.__avatar) {
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
  }
}
</style>
